import { COLORS } from "@util/constants";

export default {
  default: {
    primaryColor: COLORS.WHITE_50,
    secondaryColor: COLORS.GREY_100,
    accentColor: COLORS.TEAL_500,
    backgroundColor: COLORS.GREY_HOVER,
  },
  saq: {
    accentColor: "#e3a297",
    primaryColor: "#e3a297",
    secondaryColor: "#d08481",
  },
  dfc: {
    accentColor: "#5CBCE0",
    primaryColor: "#5CBCE0",
    secondaryColor: "#5C88E0",
  },
  onm: {
    accentColor: "#28B5B3",
    primaryColor: "#28B5B3",
    secondaryColor: "#118180",
  },
  horo: {
    accentColor: "#8273DA",
    primaryColor: "#8273DA",
    secondaryColor: "#5548A2",
  },
};
