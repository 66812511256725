module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-react-axe/gatsby-browser.js'),
      options: {"plugins":[],"showInProduction":false,"axeOptions":{}},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"shuttlefrog","short_name":"shuttlefrog","description":"Shuttlefrog","start_url":"/","background_color":"#73DFCB","theme_color":"#73DFCB","display":"minimal-ui","icon":"src/images/icon.png","icons":[{"src":"icons/icon_512x512.png","sizes":"512x512"},{"src":"icons/icon_192x192.png","sizes":"192x192"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"9c3814a8633417ba3c6e974655b34984"},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/mnt/d/Tinas Folder/myostaris/frogboat/src/components/Layout"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
